import { Controller } from 'stimulus'

const BUTTON_ENABLED_CLASS = 'bg-indigo-500'
const BUTTON_DISABLED_CLASS = 'bg-gray-200'
const SPAN_DISABLED_CLASS = 'translate-x-0'
const SPAN_ENABLED_CLASS = 'translate-x-5'

let enabled

export default class ToggleSwitch extends Controller {
  static targets = ['button', 'element']
  static values = {
    initial: Boolean,
    method: String,
    checked: String,
    unchecked: String,
    remoteFetch: Boolean,
  }

  initialize() {
    this.initialValue ? this.toggleOn() : this.toggleOff()
    enabled = this.initialValue
  }

  span() {
    return this.buttonTarget.querySelector('span')
  }

  toggle(e) {
    enabled = !enabled
    enabled ? this.toggleOn() : this.toggleOff()
    if (this.remoteFetchValue) this.submit(e)
  }

  toggleOn() {
    this.buttonTarget.classList.replace(
      BUTTON_DISABLED_CLASS,
      BUTTON_ENABLED_CLASS
    )
    this.span().classList.replace(SPAN_DISABLED_CLASS, SPAN_ENABLED_CLASS)
    this.elementTarget.setAttribute('checked', 'checked')
  }

  toggleOff() {
    this.buttonTarget.classList.replace(
      BUTTON_ENABLED_CLASS,
      BUTTON_DISABLED_CLASS
    )
    this.span().classList.replace(SPAN_ENABLED_CLASS, SPAN_DISABLED_CLASS)
    this.elementTarget.removeAttribute('checked')
  }

  submit(e) {
    this.remoteFetchController.submit(e)
  }

  get remoteFetchController() {
    return this.application.getControllerForElementAndIdentifier(
      this.elementTarget,
      'remote-fetch'
    )
  }
}
