import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
/* global Stripe */

export default class extends Controller {
  static targets = [
    'card',
    'cardErrors',
    'submit',
    'cardholderName',
    'paymentMethodId',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'city',
    'region',
    'postcode',
    'country',
  ]

  static values = {
    clientSecret: String,
    paymentMethodSent: Boolean,
  }

  connect() {
    this.stripe = Stripe(this.publishableKey)
    this.elements = this.stripe.elements()
    this.paymentMethodSentValue = false
    if (this.hasCardTarget) this._createCardElement()
  }

  confirmSetup(event) {
    if (this.paymentMethodSentValue) {
      return true
    } else {
      event.preventDefault()
    }

    this.stripe
      .confirmCardSetup(this.clientSecretValue, {
        payment_method: {
          card: this.cardElement,
          billing_details: this.billingDetails,
        },
      })
      .then((result) => {
        if (result.error) {
          this.cardErrorsTarget.textContent = result.error.message
          Rails.enableElement(this.element)
        } else {
          this.cardErrorsTarget.textContent = ''
          this.paymentMethodIdTarget.value = result.setupIntent.payment_method
          this.paymentMethodSentValue = true
          this._submitForm()
        }
      })
  }

  _createCardElement() {
    this.cardElement = this.elements.create('card', {
      classes: {
        base: this.cardTarget.dataset.baseClass,
        complete: this.cardTarget.dataset.completeClass,
        empty: this.cardTarget.dataset.emptyClass,
        focus: this.cardTarget.dataset.focusClass,
        invalid: this.cardTarget.dataset.invalidClass,
      },
      hidePostalCode: true,
    })
    this.cardElement.on('change', (event) => {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message
      } else {
        this.cardErrorsTarget.textContent = ''
      }
    })
    this.cardElement.mount(this.cardTarget)
  }

  _submitForm() {
    this.element.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )
  }

  get publishableKey() {
    return document.querySelector('meta[name="stripe_publishable_key"]').content
  }

  get billingDetails() {
    return {
      name: this.cardholderNameTarget.value,
      address: {
        city: this.cityTarget.value,
        country: this.countryTarget.value,
        line1: this.addressLine1Target.value,
        line2: this.addressLine2Target.value,
        postal_code: this.postcodeTarget.value,
        state: this.regionTarget.value,
      }
    }
  }
}
