import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    focusOnUpdate: Boolean,
    focusFirstResult: Boolean,
  }

  static targets = ['datalist', 'input']

  connect() {
    this.observer = new MutationObserver(this._resetResultHandler.bind(this))
    this.observer.observe(this.datalistTarget, { childList: true })
    this._initKeyPressHandler = this._initKeyPressHandler.bind(this)
    this._keyPressHandler = this._keyPressHandler.bind(this)
    this._init()
  }

  disconnect() {
    this._teardown()
  }

  select(e) {
    e.currentTarget.dispatchEvent(
      new CustomEvent('selected', { bubbles: true, cancelable: false })
    )
  }

  // Private methods

  _init() {
    this.inputTarget.addEventListener('keydown', this._initKeyPressHandler)
    this.datalistTarget.addEventListener('keydown', this._keyPressHandler)
  }

  _teardown() {
    this.inputTarget.removeEventListener('keydown', this._initKeyPressHandler)
    this.datalistTarget.removeEventListener('keydown', this._keyPressHandler)
  }

  _resetResultHandler(mutation) {
    this._focusFirstResult()
  }

  _focusFirstResult() {
    if (!this.focusFirstResultValue) return
    if (!this.focusOnUpdateValue) {
      this.focusOnUpdateValue = true
      return
    }

    if (this.visible) {
      this.focusedItem = this.firstItem
    } else {
      window.requestAnimationFrame(() => {
        this._focusFirstResult()
      })
    }
  }

  _next(e) {
    this.focusedItem = this.nextItem
  }

  _previous(e) {
    this.focusedItem = this.previousItem
  }

  _initKeyPressHandler(e) {
    switch (e.code) {
      case 'ArrowUp':
        e.preventDefault()
        this._makeVisible()
        this._previous(e)
        break
      case 'ArrowDown':
        e.preventDefault()
        this._makeVisible()
        this._next(e)
        break
      case 'Escape':
        e.preventDefault()
        this._makeInvisible()
        break
      default:
        break
    }
  }

  _keyPressHandler(e) {
    switch (e.code) {
      case 'ArrowUp':
        e.preventDefault()
        this._previous(e)
        break
      case 'ArrowDown':
        e.preventDefault()
        this._next(e)
        break
      case 'Enter':
        e.preventDefault()
        this.focusedItem.dispatchEvent(
          new Event('click', { bubbles: true, cancelable: false })
        )
        break
      case 'Escape':
        e.preventDefault()
        this.inputTarget.focus()
        break

      default:
        break
    }
  }

  _makeVisible() {
    this.datalistTarget.dispatchEvent(
      new CustomEvent('datalist:show', { cancelable: true, bubbles: true })
    )
  }

  _makeInvisible() {
    this.datalistTarget.dispatchEvent(
      new CustomEvent('datalist:hide', { cancelable: true, bubbles: true })
    )
  }

  get visible() {
    return this.datalistTarget.offsetParent !== null
  }

  get firstItem() {
    return this.datalistTarget.querySelector('[tabindex]')
  }

  get nextItem() {
    if (this.focusedItem && this.focusedItem.nextElementSibling) {
      if (this.focusedItem.nextElementSibling.hasAttribute('tabindex')) {
        return this.focusedItem.nextElementSibling
      } else {
        return this.focusedItem.nextElementSibling.querySelector('[tabindex]')
      }
    } else {
      return this.firstItem
    }
  }

  get previousItem() {
    if (this.focusedItem && this.focusedItem.previousElementSibling) {
      if (this.focusedItem.previousElementSibling.hasAttribute('tabindex')) {
        return this.focusedItem.previousElementSibling
      } else {
        return this.focusedItem.previousElementSibling.querySelector(
          '[tabindex]'
        )
      }
    } else {
      return this.lastItem
    }
  }

  get lastItem() {
    return this.allItems[this.allItems.length - 1]
  }

  get allItems() {
    return this.datalistTarget.querySelectorAll('[tabindex]')
  }

  get focusedItem() {
    return this.datalistTarget.querySelector('[tabindex]:focus')
  }

  set focusedItem(item) {
    if (item) {
      item.focus()
      item.dispatchEvent(
        new Event('focus', { bubbles: false, cancelable: false })
      )
    }
  }
}
