import { DataBindingController } from 'stimulus-data-bindings'

export default class extends DataBindingController {
  /**
   * @private
   * @param {String} name - the name of the binding reference
   */
  _bindingElements(name) {
    return this.element.querySelectorAll(`[data-binding-ref~="${name}"]`)
  }
}
