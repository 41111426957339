import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  submit() {
    const form = this.element.closest('form')
    Rails.disableElement(form)
    form.submit()
  }
}
