import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selProduct']

  createShipment(e) {
    const productIds = this.getSelectedProducts(e.target.dataset.orderId)
    if (productIds.length < 1) {
      return
    }

    const url = e.target.dataset.url
    // now append orderids to it
    const args = productIds.map((el) => 'product_ids[]=' + el)
    const argStr = args.join('&')
    const dispatchRequestId = e.target.dataset.dispatchRequestId
    const dispatchRequestStr =
      '?shipment[dispatch_request_id]=' + dispatchRequestId + '&'

    fetch(url + dispatchRequestStr + argStr)
      .then((response) => response.text())
      .then((data) => {
        const container = document.createElement('div')
        container.innerHTML = data
        document.body.appendChild(container.firstChild)
      })
  }

  getSelectedProducts(orderId) {
    // filters this.selOrderProductTargets for elements with the same data-order-id
    // that are checked, which will give you the order product id
    const orderElems = this.selProductTargets.filter(
      (t) => t.dataset.orderId === orderId && t.checked
    )

    return orderElems.map((t) => t.value)
  }
}
