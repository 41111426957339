import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
/* global Stripe */

export default class extends Controller {
  static targets = [
    'card',
    'cardErrors',
    'submit',
    'paymentMethodId',
    'paymentIntentId',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'city',
    'region',
    'postcode',
    'country',
    'cardholderName',
    'saved',
  ]

  static values = {
    requiresAction: Boolean,
    clientSecret: String,
    paymentMethodSent: Boolean,
    paymentMethodId: String,
    tracking: String,
  }

  connect() {
    this.stripe = Stripe(this.publishableKey)
    this.elements = this.stripe.elements()
    this.paymentMethodSentValue = false
    if (this.requiresActionValue) return this._handleCardAction()
    if (this.hasCardTarget) this._createCardElement()
  }

  createPaymentMethod(event) {
    if (
      this.paymentMethodSentValue ||
      this.savedTarget.getAttribute('card-value') ||
      this.paymentMethodIdValue
    ) {
      return true
    } else {
      event.preventDefault()
    }

    const stripeDidReceivePaymentMethodResponse =
      this._stripeDidReceivePaymentMethodResponse.bind(this)

    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.cardElement,
        billing_details: this.billingDetails,
      })
      .then(stripeDidReceivePaymentMethodResponse)
  }

  _stripeDidReceivePaymentMethodResponse(result) {
    if (result.error) {
      // show error in payment form
      this.cardErrorsTarget.textContent = result.error.message
      Rails.enableElement(this.element)
      this._submitErrors(result.error)
    } else {
      // otherwise send paymentmethod.id to server (along with everything else)
      this.cardErrorsTarget.textContent = ''
      this.paymentMethodIdTarget.value = result.paymentMethod.id
      this.paymentMethodSentValue = true
      this._submitForm()
    }
  }

  _handleCardAction() {
    this.stripe.handleCardAction(this.clientSecretValue).then((result) => {
      if (result.error) {
        this._submitErrors(result.error)
        this.cardErrorsTarget.textContent = result.error.message
        Rails.enableElement(this.element)
        this.paymentMethodIdTarget.value = ''
        this.paymentMethodIdValue = ''
        this.paymentMethodSentValue = false
        this.requiresActionValue = false
        this._createCardElement()
      } else {
        this.cardErrorsTarget.textContent = ''
        this._submitForm()
      }
    })
  }

  _createCardElement() {
    this.cardElement = this.elements.create('card', {
      classes: {
        base: this.cardTarget.dataset.baseClass,
        complete: this.cardTarget.dataset.completeClass,
        empty: this.cardTarget.dataset.emptyClass,
        focus: this.cardTarget.dataset.focusClass,
        invalid: this.cardTarget.dataset.invalidClass,
      },
      hidePostalCode: true,
    })
    this.cardElement.on('change', (event) => {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message
      } else {
        this.cardErrorsTarget.textContent = ''
      }
    })
    this.cardElement.mount(this.cardTarget)
  }

  _submitErrors(error) {
    const url = `/stripe_payment_attempts`
    const data = {
      account_id: this.trackingValue,
      stripe_payment_method_id: error.payment_method?.id,
      stripe_payment_intent_id: error.payment_intent?.id,
      error_code: error.code,
      error_message: error.message,
      amount_pence: error.payment_intent?.amount,
    }
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    })
  }

  _submitForm() {
    this.element.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )
  }

  get publishableKey() {
    return document.querySelector('meta[name="stripe_publishable_key"]').content
  }

  get billingDetails() {
    return {
      name: this.cardholderNameTarget.value,
      address: {
        city: this.cityTarget.value,
        country: this.countryTarget.value,
        line1: this.addressLine1Target.value,
        line2: this.addressLine2Target.value,
        postal_code: this.postcodeTarget.value,
        state: this.regionTarget.value,
      },
    }
  }
}
