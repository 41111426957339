import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['cloneFrom', 'cloneTo', 'cloneTrigger']

  clone() {
    const condition = this._cloneCondition()
    if (condition === false) return

    const fields =
      'select, input[type="number"], input[type="text"], input[type="checkbox"]'
    const currentFields = this.cloneToTarget.querySelectorAll(fields)
    const previousFields = this.cloneFromTarget.querySelectorAll(fields)

    for (let i = 0; previousFields.length > i; i++) {
      const element = previousFields[i]

      if (element.type === 'checkbox') {
        currentFields[i].checked = element.checked
      } else {
        currentFields[i].value = element.value
      }
    }
  }

  _cloneCondition() {
    if (this.cloneTriggerTarget.type === 'checkbox') {
      return this.cloneTriggerTarget.checked === true
    } else {
      return this.cloneTriggerTarget.value === true
    }
  }
}
