import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  // This controller submits the form as formdata as a turbo stream and renders the stream response to the page

  static values = {
    url: String,
    method: String,
  }

  submit(event) {
    const body = new FormData(this.element)
    const url = event.target.dataset.url || this.urlValue || this.element.action
    const method =
      event.target.dataset.method || this.methodValue || this.element.method
    const headers = {
      Accept: 'text/vnd.turbo-stream.html',
      'X-CSRF-Token': Rails.csrfToken(),
    }
    fetch(url, { method, headers, body })
      .then((response) => response.text())
      .then((html) => {
        document.body.insertAdjacentHTML('beforeend', html)
      })
  }
}
