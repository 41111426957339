import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.dispatchEvent(
      new CustomEvent('existence:added', {
        bubbles: true,
        cancelable: false,
      })
    )
  }

  remove() {
    this.element.dispatchEvent(
      new CustomEvent('existence:removed', {
        bubbles: true,
        cancelable: false,
      })
    )
    this.element.remove()
  }
}
