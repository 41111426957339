import { Controller } from 'stimulus'
import { debounce } from 'lodash'

export default class extends Controller {
  static values = {
    url: String,
    debounce: Number,
    preload: Boolean,
    showOnPreload: Boolean,
    param: String,
  }

  static targets = [
    'input',
    'field',
    'results',
    'searchIcon',
    'clearIcon',
    'errorIcon',
  ]

  connect() {
    if (this.preloadValue) {
      this.search(null, this.showOnPreloadValue)
    }

    this.search = debounce(this.search, this.debounceValue)
    this._blurHandler = debounce(this._blurHandler, 10).bind(this)
    this._focusHandler = debounce(this._focusHandler, 10).bind(this)
    this.element.addEventListener('focusout', this._blurHandler)
    this.inputTarget.addEventListener('focus', this._focusHandler)
  }

  disconnect() {
    this.element.removeEventListener('focusout', this._blurHandler)
    this.inputTarget.removeEventListener('focus', this._focusHandler)
  }

  search(e, openResults = true) {
    const url = new URL(window.location.href)
    const urlSplit = this.urlValue.split('?')
    url.pathname = urlSplit[0]
    if (urlSplit.length > 1) url.search = urlSplit[1]
    const searchParams = url.searchParams

    if (!this.preloadValue || e) {
      searchParams.append(this.paramValue, this.inputTarget.value)
    }
    fetch(url.href)
      .then((response) => response.text())
      .then((data) => {
        const template = document.createElement('template')
        template.innerHTML = data
        this._renderResults(template, openResults)
      })
  }

  select(e) {
    this.hide()
    this.inputTarget.value = e.currentTarget.dataset.value
    this.fieldTarget.value = e.currentTarget.dataset.id
    this.inputTarget.disabled = true
    this.searchIconTarget.hidden = true
    if (this.hasErrorIconTarget) this.errorIconTarget.hidden = true
    this.clearIconTarget.hidden = false

    const event = new CustomEvent('autocomplete:selected', { bubbles: true })
    this.fieldTarget.dispatchEvent(event)
    this.fieldTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  clear(e) {
    const clearEvent = new CustomEvent('autocomplete:clear', { bubbles: true })
    this.fieldTarget.dispatchEvent(clearEvent)
    this.inputTarget.value = ''
    this.fieldTarget.value = ''
    this.inputTarget.disabled = false
    if (!this.hasErrorIconTarget) this.searchIconTarget.hidden = false
    if (this.hasErrorIconTarget) this.errorIconTarget.hidden = false
    this.clearIconTarget.hidden = true

    const clearedEvent = new CustomEvent('autocomplete:cleared', {
      bubbles: true,
    })
    this.fieldTarget.dispatchEvent(clearedEvent)
  }

  focus() {
    this.inputTarget.focus()
    this.inputTarget.dispatchEvent(new Event('focus'))
  }

  show() {
    const event = new Event('autocomplete:show', {
      cancelable: false,
      bubbles: true,
    })
    this.resultsTarget.dispatchEvent(event)
  }

  hide() {
    const event = new Event('autocomplete:hide', {
      cancelable: false,
      bubbles: true,
    })
    this.resultsTarget.dispatchEvent(event)
  }

  // private
  _renderResults(template, openResults) {
    this.resultsTarget.innerHTML = ''
    this.resultsTarget.appendChild(template.content)
    if (this.fieldTarget.value !== '') {
      const selectedOption = this.resultsTarget.querySelector(
        `[data-id='${this.fieldTarget.value}']`
      )
      if (selectedOption) selectedOption.dataset.selected = true
    }
    if (openResults) this.show()
  }

  _focusHandler(e) {
    if (this.preloadValue || this.inputTarget.value !== '') this.show()
  }

  _blurHandler(e) {
    if (this.resultsTarget.querySelector(':focus')) return

    this.hide()
  }
}
