// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
// import { ExistenceController } from 'stimulus-existence'
import { DataBindingController } from 'stimulus-data-bindings'
// import { RevealController } from 'stimulus-reveal'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import consumer from '../channels/consumer'

const application = Application.start()
// application.register('existence', ExistenceController)
application.register('data-binding', DataBindingController)
// application.register('reveal', RevealController)
const context = require.context('controllers', true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))

// Stimulus Reflex setup
application.consumer = consumer
StimulusReflex.initialize(application, { isolate: true })
